<template>
  <div class="c-main--flex-1 u-margin-bottom-small">
    <round v-for="item in data" :id="item.round.index" :key="item.round.index"
           v-model="item.round" :rounds="other(item.round.index)" @remove="remove($event)"/>
    <div class="o-layout">
      <div class="o-layout__item">
        <button class="c-button c-button--primary c-button--large" v-on:click.prevent="add()">
          {{$t('round.add')}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import cloneDeep from "lodash/cloneDeep"
  import rounds from 'client/lib/rounds'
  import map from "lodash/map"
  import filter from "lodash/filter"

  export default {
    name: "rounds-wrapper",
    components: {
      round: require('./round.vue').default,
    },
    props: ['value', 'discipline'],
    data: function() {
      return {
        data: []
      }
    },
    watch: {
      data: {
        deep: true,
        handler: function() {
          const val = this.data.map(i => i.round)
          console.log('update rounds', val)
          this.$emit('input', val)
        },
      },
    },
    created: function() {
      const data = cloneDeep(this.value)
      this.data = data.map(round => ({ round }))
    },
    methods: {
      other: function (index) {
        return map(filter(this.data, r => r.round.index > index), i => ({
          index: i.round.index,
          name: i.round.name
        }))
      },
      remove: function() {
        this.data = this.data.slice(0, this.data.length - 1)
      },
      add: function () {
        // TODO: add create round function
        this.data.push({ round: rounds.createRound(this.data.length, this.discipline) })
      },
    },
  }
</script>

<style scoped>
</style>
