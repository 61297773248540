import { render, staticRenderFns } from "./rounds.vue?vue&type=template&id=142aa163&scoped=true&"
import script from "./rounds.vue?vue&type=script&lang=js&"
export * from "./rounds.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "142aa163",
  null
  
)

export default component.exports